import { gql, useMutation } from '@apollo/client';
import { LOGIN_MUTATION } from '../../queries/mutations/login';

const LOGIN = gql`
    mutation Login($login: String!, $password: String!) {
        loginWithCookies(input: { login: $login, password: $password }) {
            status
        }
    }
`;

const useLoginMutation = () => {
    const [mutation, mutationResults] = useMutation(LOGIN_MUTATION);

    const loginMutation = (username: string, password: string) => {
        return mutation({
            variables: {
                login: username,
                password,
            },
        });
    };

    return { loginMutation, results: mutationResults };
};

export default useLoginMutation;
