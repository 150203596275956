import React, { useEffect, useState } from 'react';
import { useSiteContext } from '../../contexts/SiteContext';
import useAuth from './useAuth';

const useIsLoggedIn = () => {
    const { isLoggedIn: ctxIsLoggedIn } = useAuth();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    useEffect(() => {
        setIsLoggedIn(ctxIsLoggedIn);
    }, [ctxIsLoggedIn]);

    return isLoggedIn;
};

export default useIsLoggedIn;
