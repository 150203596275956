const SvgGbStar = (props) => (
	<svg
		width={20}
		height={19}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="m10.115.512 2.245 6.91h7.266l-5.878 4.27 2.245 6.91-5.878-4.27-5.878 4.27 2.246-6.91-5.878-4.27H7.87l2.245-6.91Z"
			fill={props.fillColor ? props.fillColor : "#AF8C5A"}
		/>
	</svg>
);

export default SvgGbStar;
