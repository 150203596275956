import React from 'react';
import AmericanExpress from './payment-icons/AmericanExpress';
import ApplePay from './payment-icons/ApplePay';
import Paypal from './payment-icons/Paypal';
import Zip from './payment-icons/Zip';
import MasterCard from './payment-icons/MasterCard';
import Visa from './payment-icons/Visa';
import Eft from './payment-icons/Eft';
import DinersClub from './payment-icons/DinersClub';
import Afterpay from './payment-icons/Afterpay';
import GooglePay from './payment-icons/GooglePay';

type Props = {};

const PaymentIcons = (props: Props) => {
    return (
        <div className="container flex flex-wrap xl:flex-nowrap justify-center gap-10 xl:gap-16 py-11 px-1">
            <Zip className="w-14 lg:w-autos h-[30px]" />
            <Paypal className="w-20 lg:w-autos h-[30px]" />
            <ApplePay className="w-11 lg:w-autos h-[30px]" />
            <AmericanExpress className="w-7 lg:w-autos h-[30px]" />
            <MasterCard className="w-9 lg:w-autos h-[30px]" />
            <Visa className="w-16 lg:w-autos h-[30px]" />
            <Eft className="w-8 lg:w-autos h-[30px]" />
            <DinersClub className="w-20 lg:w-autos h-[30px]" />
            <Afterpay className="w-24 lg:w-autos h-[30px]" />
            <GooglePay className="w-14 lg:w-autos h-[30px]" />
        </div>
    );
};

export default PaymentIcons;
