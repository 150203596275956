import * as React from "react";

const SvgGbMinus = (props) => (
  <svg
    width={17}
    height={4}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.973.857H.953a.327.327 0 0 0-.327.327v1.959c0 .18.147.327.327.327h15.02c.18 0 .326-.147.326-.327v-1.96a.327.327 0 0 0-.326-.326Z"
      fill="#CE2144"
    />
  </svg>
);

export default SvgGbMinus;
