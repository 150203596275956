import * as React from "react";

const SvgZipLogo = (props) => (
  <svg
    width={56}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m2.16 16.52.478 3.878h16.714l-.547-4.441h-7.793l-.069-.55 7.178-5.002-.48-3.885H.93l.547 4.44h7.806l.068.556-7.19 5.004Z"
      fill="#000"
    />
    <path
      d="m19.583 6.52 1.71 13.878h16.726L36.31 6.52H19.584Z"
      fill="#AA8FFF"
    />
    <path
      d="M55.56 11.516c-.387-3.119-2.844-5.01-6.184-4.996H38.25L39.959 20.4h5.005l-.342-2.776h5.297c4.168-.001 6.075-2.59 5.64-6.107Zm-6.183 2.216-5.233.006-.41-3.331 5.263.004c1.237.015 1.87.71 1.972 1.66.063.612-.218 1.66-1.592 1.66ZM24.58 4.676c.88-.942.727-2.511-.342-3.504-1.07-.994-2.65-1.035-3.53-.093-.881.942-.728 2.511.342 3.505 1.07.993 2.65 1.035 3.53.092Z"
      fill="#000"
    />
  </svg>
);

export default SvgZipLogo;
