import * as React from "react";

const SvgGbBars = (props) => (
  <svg
    width={22}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.195 8.135h21v-2.27h-21v2.27Zm0 5.676h21v-2.27h-21v2.27Zm0-13.622v2.27h21V.19h-21Z"
      fill="#B18D56"
    />
  </svg>
);

export default SvgGbBars;
