import React, { useEffect, useState } from 'react';
import { useLocalStorage } from 'usehooks-ts';

const useUserDetails = () => {
    const [user, setUser] = useLocalStorage('userDetails', null);

    const [userDetails, setUserDetails] = useState(null);
    useEffect(() => {
        setUserDetails(user);
    }, [user]);

    return {
        userDetails,
        setUserDetails: setUser,
    };
};

export default useUserDetails;
