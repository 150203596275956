import * as React from "react";

const SvgGbChevronUp = (props) => (
	<svg
		width={props.width ? props.width : 12}
		height={props.height ? props.height : 10}
		viewBox="0 0 12 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="M11.703 9.179 6.17.75.48 9.179h11.223Z" fill={props.fillColor ? props.fillColor : "#000"} />
	</svg>
);

export default SvgGbChevronUp;
