import * as React from "react";

const SvgGbMenuCarret = (props) => (
  <svg
    width={9}
    height={7}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m8.5.5-4.03 6L.5.5h8Z" fill="#fff" />
  </svg>
);

export default SvgGbMenuCarret;
