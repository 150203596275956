import React from 'react';
type Props = {
    className?: string;
};
const Visa = ({ className }: Props) => {
    return (
        <svg
            width="95"
            height="31"
            viewBox="0 0 95 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M61.4096 0.154785C54.7267 0.154785 48.7546 3.61867 48.7546 10.0185C48.7546 17.3578 59.3464 17.8648 59.3464 21.5519C59.3464 23.1043 57.5673 24.494 54.5286 24.494C50.2161 24.494 46.9929 22.5522 46.9929 22.5522L45.6138 29.0103C45.6138 29.0103 49.3268 30.6506 54.2565 30.6506C61.563 30.6506 67.3124 27.0166 67.3124 20.5074C67.3124 12.752 56.6764 12.2602 56.6764 8.83792C56.6764 7.62178 58.1369 6.28925 61.1669 6.28925C64.5857 6.28925 67.3749 7.7015 67.3749 7.7015L68.7247 1.46406C68.7247 1.46406 65.6897 0.154785 61.4096 0.154785ZM0.267289 0.625536L0.105469 1.56704C0.105469 1.56704 2.91699 2.08159 5.44922 3.10801C8.70968 4.285 8.94194 4.97019 9.49105 7.09835L15.4747 30.1651H23.4959L35.8531 0.625536H27.8503L19.9101 20.7097L16.67 3.68541C16.3728 1.737 14.8677 0.625536 13.0254 0.625536H0.267289ZM39.0711 0.625536L32.7932 30.1651H40.4245L46.6803 0.625536H39.0711ZM81.6335 0.625536C79.7933 0.625536 78.8183 1.61074 78.1029 3.33235L66.9226 30.1651H74.9253L76.4737 25.693H86.2233L87.1648 30.1651H94.2261L88.0659 0.625536H81.6335ZM82.6743 8.60622L85.0465 19.6909H78.6913L82.6743 8.60622Z"
                fill="#1434CB"
            />
        </svg>
    );
};

export default Visa;
