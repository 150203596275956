import React from 'react';
type Props = {
    className?: string;
};

const Zip = ({ className }: Props) => {
    return (
        <svg
            width="84"
            height="31"
            viewBox="0 0 84 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M1.98575 24.7654L2.71594 30.6968H28.2802L27.4437 23.904H15.5238L15.4197 23.0623L26.3977 15.4135L25.6642 9.47118H0.102173L0.93871 16.2629H12.8783L12.9824 17.1123L1.98575 24.7654Z"
                fill="white"
            />
            <path
                d="M28.6343 9.47118L31.2492 30.6968H56.8321L54.2172 9.47118H28.6343Z"
                fill="#AA8FFF"
            />
            <path
                d="M83.6594 17.1123C83.0695 12.3418 79.3112 9.45041 74.202 9.47118H57.1851L59.7989 30.6979H67.4549L66.9308 26.4521H75.032C81.4074 26.451 84.3249 22.4906 83.6594 17.1123ZM74.2042 20.501L66.2007 20.5098L65.5735 15.4157L73.6221 15.4223C75.5144 15.4452 76.4825 16.5078 76.6393 17.9616C76.7358 18.8974 76.306 20.4999 74.2042 20.4999V20.501Z"
                fill="white"
            />
            <path
                d="M36.2775 6.65083C37.624 5.20967 37.3893 2.80966 35.7533 1.29028C34.1174 -0.229119 31.6997 -0.292537 30.3534 1.14863C29.0069 2.58979 29.2416 4.98978 30.8775 6.50918C32.5134 8.02857 34.9312 8.09199 36.2775 6.65083Z"
                fill="white"
            />
        </svg>
    );
};

export default Zip;
