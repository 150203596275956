import { useRouter } from 'next/router';
import { useState, createContext, useRef, useEffect, useContext } from 'react';
import { useEffectOnce, useLocalStorage } from 'usehooks-ts';
import { useMutation, useQuery } from '@apollo/client'
import GET_CART from '../utils/queries/cart';
import { isEmpty } from 'lodash';
import { getFormattedCart } from '@/utils/cart'
import jwtDecode from 'jwt-decode';
import useIsCartHasCookie from '../utils/hooks/useIsCartHasCookie';
import useIsCartHasHamper from '../utils/hooks/useIsCartHasHamper';
import { useLazyQuery } from '@apollo/client';

export const SiteContext = createContext()

export const SiteContextProvider = (props) => {

    const { asPath, push, pathname } = useRouter()
    const [history, setHistory] = useState([])
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isMegaMenuOpen, setIsMegaMenuOpen] = useState(false)

    const [miniCartOpen, setMiniCartOpen] = useState(false)
    const [cartKey, setCartKey] = useLocalStorage('woo-cart-key');
    const [cart, setCart] = useLocalStorage('woo-next-cart');

    const [wooCustomerSessionId, setWooCustomerSessionId] = useState('woo-customer-session-id', null);

    const [cartUpdating, setCartUpdating] = useState(false)
    const cartHasCookie = useIsCartHasCookie(cart)
    const cartHasHamper = useIsCartHasHamper(cart)

    const [refetch, { called, loading, error, data }] = useLazyQuery(GET_CART, {
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {


            try {
                // Update cart data in React Context.
                setCart(getFormattedCart(data));

                const sessionToken = localStorage.getItem('woo-session');
                if (!isEmpty(sessionToken)) {
                    // jtw-decode is an open-source package
                    const decoded = jwtDecode(
                        sessionToken
                    );
                    setWooCustomerSessionId(decoded.data.customer_id);
                }
            } catch (error) {
                console.error('useEffect: ' + error.message);
                setWooCustomerSessionId(null);
            }
        }
    });

    useEffectOnce(() => {
        if (cart && parseInt(cart.totalProductsCount) >= 1) {
            refetch();
        }
    });

    const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isLoggedIn', false)



    const back = () => {
        for (let i = history.length - 2; i >= 0; i--) {
            const route = history[i]
            if (!route.includes('#') && route !== pathname) {
                push(route)
                // if you want to pop history on back
                const newHistory = history.slice(0, i)
                setHistory(newHistory)
                break
            }
        }
    }

    const getPrevUrl = () => {
        return history.at(-2)
    }

    useEffect(() => {
        setHistory(previous => [...previous, asPath])
        setIsMobileMenuOpen(false)
        setIsMegaMenuOpen(false);
    }, [asPath])



    return (
        <SiteContext.Provider value={{
            history: {
                back,
                list: history,
                getPrevUrl,
                setHistory,
            },
            miniCartState: [miniCartOpen, setMiniCartOpen],
            cartKey: [cartKey, setCartKey],
            cart: cart,
            setCart: setCart,
            isMobileMenuOpen, setIsMobileMenuOpen,
            fetchCart: refetch,
            fetchingCart: loading,
            cartUpdating,
            setCartUpdating,
            wooCustomerSessionId,
            isMegaMenuOpen,
            setIsMegaMenuOpen,
            isLoggedIn, setIsLoggedIn,
            cartHasCookie,
            cartHasHamper
        }}>
            {props.children}
        </SiteContext.Provider>
    )
}

export const useSiteContext = () => {
    return useContext(SiteContext)
}