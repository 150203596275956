import * as React from "react";

const SvgGbBasket = (props) => (
	<svg
		width={21}
		height={18}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
		className='cursor-pointer'
	>
		<path
			d="M19.25 7.763H.987C.523 7.763 0 8.275 0 8.906v.145c0 .631.523 1.152 1.158 1.152h.3l1.03 6.09c.154.88.257 1.707 1.716 1.707h12.172c1.424 0 1.527-.75 1.716-1.706l1.03-6.091h.3c.635 0 1.158-.52 1.158-1.152v-.145c-.009-.631-.523-1.143-1.33-1.143ZM4.847 15.731a.636.636 0 0 1-.686-.589l-.275-4.171a.632.632 0 0 1 .592-.674h.009a.633.633 0 0 1 .677.588l.275 4.172a.65.65 0 0 1-.592.674Zm4.22-.623c0 .35-.283.631-.634.631h-.009a.632.632 0 0 1-.635-.63v-4.181c0-.35.283-.631.635-.631h.009c.351 0 .635.281.635.631v4.18Zm3.767 0c0 .35-.284.631-.635.631h-.009a.632.632 0 0 1-.635-.63v-4.181c0-.35.283-.631.635-.631h.009c.351 0 .635.281.635.631v4.18Zm3.911-4.137-.274 4.171a.634.634 0 0 1-.678.589h-.008a.632.632 0 0 1-.592-.674l.274-4.172a.633.633 0 0 1 .678-.588h.009a.644.644 0 0 1 .591.674ZM4.675 3.336c.172-.794.558-1.382 1.802-1.382H7.66a.862.862 0 0 0 .832.665h3.586a.853.853 0 0 0 .832-.665h1.184c1.218 0 1.63.614 1.801 1.382l.806 3.753h1.313l-.995-4.65c-.206-.946-1.287-1.705-2.23-1.705h-1.862a.858.858 0 0 0-.85-.734H8.494a.858.858 0 0 0-.85.734H5.783c-.944 0-2.025.725-2.23 1.706l-.996 4.65H3.87l.806-3.754Z"
			fill="#B18D56"
		/>
	</svg>
);

export default SvgGbBasket;
