import React from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

type Props = {};

const Socials = (props: Props) => {
    return (
        <>
            <a href="https://www.facebook.com/GourmetBasketOz/">
                <FaFacebookF className=" text-white h-[22px] w-full" />
            </a>
            <a href="https://www.instagram.com/gourmetbasketoz/">
                <FaInstagram className=" text-white h-[22px] w-full" />
            </a>
            <a href="https://www.linkedin.com/company/gourmet-basket/">
                <FaLinkedinIn className=" text-white h-[22px] w-full" />
            </a>
        </>
    );
};

export default Socials;
