import Link from 'next/link';
import { GbBars, GbPhone, GbTruck } from '../../../components/icons';

import Logo from '../../../components/common/Logo';
import React from 'react';
import { useSiteContext } from '../../../contexts/SiteContext';
import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { CgClose } from 'react-icons/cg';
import CartBasketIcon from './CartBasketIcon';
import SiteMessage from '../../common/SiteMessage';
import useIsLoggedIn from '../../../utils/hooks/useIsLoggedIn';
import { cn } from '../../../utils';

import dynamic from 'next/dynamic';

const Search = dynamic(
  () => import('../../../components/layout/header/Search/Search'),
  {
    loading: () => <p>Loading...</p>,
  },
);

const MiniCart = dynamic(() => import('./MiniCart/MiniCart'), {
  loading: () => <p>Loading...</p>,
});

const Login = dynamic(() => import('./Login'), {
  loading: () => <p>Loading...</p>,
});

const MainMenu = dynamic(
  () => import('../../../components/layout/header/MainMenu'),
  {
    loading: () => <p>Loading...</p>,
  },
);

export default function MainHeader() {
  const { isMobileMenuOpen, setIsMobileMenuOpen } = useSiteContext();
  const isLoggedIn = useIsLoggedIn();

  return (
    <header>
      <SiteMessage />
      <div className="bg-brand-secondary">
        <div className="flex container justify-between items-center xl:items-start  px-3 py-2 md:py-7 lg:py-3 lg:px-6 xl:px-0 xl:py-2">
          <div className="gap-x-10 items-center hidden xl:!flex">
            <Link
              href={`tel:1300 354 393`}
              aria-label="1300 354 393"
              className="flex gap-4 text-brand-primary uppercase items-center font-montserrat-500 text-sm"
            >
              <GbPhone /> <span> 1300 354 393</span>
            </Link>
            <Link
              href={`/delivery`}
              aria-label="Delivery"
              className="flex gap-4 text-brand-primary uppercase items-center font-montserrat-500 text-sm"
            >
              <GbTruck /> <span>Delivery</span>
            </Link>
          </div>

          <div className="flex gap-x-4 items-center xl:hidden">
            <button
              aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
              onClick={(e) => setIsMobileMenuOpen((prev) => !prev)}
            >
              {isMobileMenuOpen ? (
                <CgClose
                  className="h-9 w-[22px] text-brand-primary"
                  aria-hidden="true"
                />
              ) : (
                <GbBars />
              )}
            </button>
            <Link href={`/delivery`} aria-label="Delivery">
              <GbTruck />
            </Link>
          </div>
          <div className="xl:hidden">
            <Logo className="max-w-[164px] max-h-[60px] lg:max-w-[218px] lg:max-h-[80px]" />
          </div>
          <div className="flex gap-x-4 items-center xl:hidden">
            <Link href={`tel:1300 354 393`} aria-label="1300 354 393">
              <GbPhone />
            </Link>
            <CartBasketIcon />
          </div>
          <div className="gap-x-3 items-center hidden xl:!flex">
            <div className="max-w-[147px] mr-3">
              <Search />
            </div>

            {isLoggedIn ? (
              <div className="border-r border-r-brand-primary-light">
                <Link
                  href="/my-account"
                  className="group inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-4 focus:ring-offset-brand-secondary  cursor-pointer uppercase  text-brand-primary pr-3 leading-4 font-montserrat-500 text-sm"
                >
                  My Account
                </Link>
              </div>
            ) : (
              <Popover className="relative" as="div">
                {({ open }) => (
                  <>
                    <span className="border-r border-r-brand-primary-light">
                      <Popover.Button
                        className={cn(
                          open
                            ? 'text-brand-gold-disabled'
                            : 'text-brand-primary',
                          'group inline-flex items-center font-medium focus:outline-none focus:ring-2 focus:ring-brand-primary focus:ring-offset-4 focus:ring-offset-brand-secondary  cursor-pointer uppercase  text-brand-primary pr-3 leading-4 font-montserrat-500 text-sm',
                        )}
                      >
                        Login
                      </Popover.Button>
                    </span>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                        <div className="overflow-hidden rounded-sm shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                            <Login />
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            )}

            <CartBasketIcon />
          </div>
          <MiniCart />
        </div>
        <div className="justify-center container relative top-[-19px] hidden xl:!flex">
          <Logo className="max-w-[164px] max-h-[60px] lg:max-w-[218px] lg:max-h-[80px]" />
        </div>

        <div className="px-2 pb-2 lg:px-6 xl:hidden">
          <Search />
        </div>
      </div>

      <MainMenu />
    </header>
  );
}
