import Head from 'next/head';
import React from 'react';
import Logo from '../components/common/Logo';

type Props = {};

const Maintenance = (props: Props) => {
  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <div className="bg-brand-secondary lg:bg-white flex justify-center items-center flex-1 h-screen">
        <div className="bg-brand-secondary rounded-md">
          <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-32">
            <div className="mx-auto max-w-2xl text-center">
              <div className="flex justify-center mb-5">
                <Logo className="max-w-[164px] max-h-[60px] lg:max-w-[218px] lg:max-h-[80px]" />
              </div>

              <h2 className="text-3xl font-bold tracking-tight text-brand-primary sm:text-4xl">
                We&apos;ve got something
                <br /> special in store for you.
              </h2>
              <p className="mx-auto mt-6 max-w-xl text-lg leading-8 text-white">
                And we can&apos;t wait for you to have it. <br />
                Please check back soon.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Maintenance;
