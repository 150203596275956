import pkg, { isEmpty, keyBy } from 'lodash';
import { unCacheTSClient } from './client';
import path from 'path';
import siteInfo from '../../data/site_info.json';

const filePath = path.join(process.cwd(), '/src/data/site_info.json');

export const generateSearchParams = (name: string) => {
  let searchParameters = {
    q: name,
    query_by: 'name',
    page: 1,
    per_page: 1,
  };

  return searchParameters;
};

export const document = () => {
  return unCacheTSClient
    .collections(`site_info-${process.env.NEXT_PUBLIC_TS_STORE_ID}`)
    .documents();
};

export const fetchInfo = async () => {
  let searchParameters = {
    q: '*',
    query_by: 'name',
    page: 1,
    per_page: 250,
  };
  const results = await unCacheTSClient
    .collections(`site_info-${process.env.NEXT_PUBLIC_TS_STORE_ID}`)
    .documents()
    .search(searchParameters);

  let found: any = [];
  if (typeof results.hits !== 'undefined' && results.hits) {
    results.hits.map((doc, i) => {
      const tsProductData = JSON.parse(JSON.stringify(doc.document));
      found.push(tsProductData);
    });

    const info = JSON.stringify(keyBy(found, 'name'), null, 4);
  }

  return filePath;
};

export const fetchInfoByName = async (name: string) => {
  let searchParameters = {
    q: name,
    query_by: 'name',
    page: 1,
    per_page: 1,
  };
  const results = await unCacheTSClient
    .collections(`site_info-${process.env.NEXT_PUBLIC_TS_STORE_ID}`)
    .documents()
    .search(searchParameters);

  let found: any = null;
  if (typeof results.hits !== 'undefined' && results.hits) {
    results.hits.map((doc, i) => {
      const tsProductData = JSON.parse(JSON.stringify(doc.document));
      found = tsProductData;
    });
  }

  return found;
};

export const getSiteMessage = () => {
  const siteMsgBlock = JSON.parse(siteInfo.site_message_top_header.value);
  let found = '';
  if (!isEmpty(siteMsgBlock[0]) && !isEmpty(siteMsgBlock[0].metaData.AU.text)) {
    found = siteMsgBlock[0].metaData.AU.text;
  }

  return found;
};

const TSSiteInfo = {
  fetchInfo,
  fetchInfoByName,
  generateSearchParams,
  document,
  getSiteMessage,
  filePath,
};
export default TSSiteInfo;
