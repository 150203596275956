import * as React from "react";

const SvgGbAddonItemImgPlaceholder = (props) => (
  <svg
    width={63}
    height={55}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path fill="#EFEFEF" d="M0 0h63v55H0z" />
    <path
      d="M25.315 15.923c-.544-.382-1.556-.688-2.256-.84a8.03 8.03 0 0 1 2.256.84Z"
      fill="#fff"
    />
    <path
      d="M28.66 23.794c.778-.764 1.167-1.681 1.167-2.751 0-1.758-.778-3.21-2.256-4.433-.389-.305-.778-.534-1.167-.84l-.233-.076v-.077h-.544v.076l-.156.077c-.467.23-.856.535-1.167.84-1.478 1.223-2.256 2.675-2.256 4.433 0 1.07.389 1.987 1.167 2.75.233.23.467.383.7.536.078.076.156.076.233.152.078.077.156.077.312.153.31.153.7.23 1.089.23h.389c.544 0 1.011-.077 1.478-.306.078-.076.233-.076.31-.153.079 0 .156-.076.234-.153h.078c.156-.076.389-.229.622-.458Z"
      fill="#AF8C5A"
    />
    <path
      d="M26.249 16s.7-1.223 5.212-1.3h3.812l-.078 20.556v2.751c0 .612-.233 1.147-.622 1.529-.39.458-.934.611-1.556.611h-.39v.459h16.026c2.956 0 5.29-.688 7.08-1.987 1.633-1.3 2.489-2.904 2.489-4.967 0-1.91-.856-3.515-2.645-4.815-1.634-1.222-3.579-1.986-5.99-2.292v-.23c1.944-.382 3.5-1.07 4.667-2.063 1.323-1.146 1.945-2.445 1.945-3.897 0-1.91-.933-3.362-2.8-4.509-1.79-.993-3.968-1.528-6.691-1.528H32.005c-2.411 0-4.434.382-6.067 1.146-1.945-.688-4.279-1.222-6.768-1.222-1.167 0-2.334.076-3.345.305-1.245.153-2.49.612-3.657 1.223-1.322.611-2.489 1.605-3.5 2.598-2.645 2.598-3.89 6.114-3.89 9.552.078 3.363 1.323 6.343 3.89 8.865 2.645 2.598 5.6 3.974 8.946 3.974 1.089 0 2.023-.077 2.878-.306.467-.153 1.09-.382 1.79-.764.544-.382 1.088-.688 1.71-1.07 0 0 1.323-1.07 2.646-.994 0 0 1.633 0 2.022 1.529.078.153.078.305.078.535v.993h.39v-10.47c0-.61.155-1.069.544-1.375.389-.305.778-.458 1.244-.458h.7v-.459h-11.28v.459h.7c1.479 0 1.79.611 1.79 1.834v5.35c-.078 1.451-.545 4.431-3.734 4.737 0 0-3.423.611-5.523-3.286a12.34 12.34 0 0 1-.467-.993c-.778-1.91-1.4-4.585-1.167-8.483 0-4.355.778-7.794 1.867-9.552 1.4-2.216 4.512-4.05 8.402-3.21 0 0 .233.077.622.153.078.077.156.077.233.077.7.153 1.712.458 2.256.84l.934.153Zm14.625-1.07h4.9c.545 0 .934.152 1.323.458 1.167.764 1.79 2.445 1.79 4.967 0 1.91-.078 3.133-.312 3.744-.311.841-.778 1.529-1.478 1.834-.39.23-.778.383-1.244.383h-4.824V14.928h-.155ZM49.12 39c-.39.46-1.089.842-2.1.994-.468.153-1.012.23-1.712.23h-.623c-1.555 0-2.878-.153-3.267-.459-.31-.23-.466-.84-.466-1.681V26.85h4.667c.545 0 1.011.077 1.4.306 2.023.764 3.035 2.904 3.035 6.42 0 2.827-.312 4.584-.934 5.425Z"
      fill="#AF8C5A"
    />
  </svg>
);

export default SvgGbAddonItemImgPlaceholder;
