import React from 'react';
import Link from 'next/link';
import Image from '../../../components/common/Image';

type Props = {};

const Rewards = (props: Props) => {
  return (
    <>
      <div className="py-9 grid place-content-center bg-[#F1F2ED] space-y-5 px-5 md:hidden">
        <p className="text-center text-brand-primary-light font-playfairdisplay font-black text-[28px]">
          GB REWARDS
        </p>

        <p className="font-playfairdisplay italic text-[28px] text-center">
          <strong className="font-playfairdisplay italic text-[28px] text-center">
            Join today and receive a $10 welcome reward
          </strong>{' '}
          plus ongoing rewards for every $1 you spend
        </p>
        <div className="flex justify-center mb-1">
          <Link
            href="/gb-rewards/"
            className="button-black-outline bg-[#F1F2ED] uppercase text-xs text-black py-2 px-12"
          >
            Join Now
          </Link>
        </div>
      </div>
      <div className="relative ">
        <div className="absolute inset-x-0 bottom-0" />
        <div className="mx-auto max-w-full ">
          <div className="relative sm:overflow-hidden">
            <div className="absolute inset-0">
              <Image
                fill
                src={
                  'https://cdn.gourmetbasket.com.au/wp-content/uploads/2021/07/GB-Rewards_2560px-wide.jpg.webp'
                }
                alt="GB Rewards"
                className="h-full w-full object-cover object-custom-81-center md:object-center"
              />
              <div className="absolute inset-0 mix-blend-multiply" />
            </div>
            <div className={`relative h-[196px] md:h-[371px]`}>
              <div className="container flex items-center mx-auto  space-y-5 h-full">
                <div className="hidden px-5 py-8  w-full md:w-[60%] space-y-3 md:flex flex-col items-center md:items-start">
                  <h4 className=" text-center text-brand-primary-light font-playfairdisplay font-black text-[28px]">
                    GB REWARDS
                  </h4>

                  <h5 className="font-playfairdisplay italic text-[28px] text-center md:text-left">
                    <strong className="font-playfairdisplay italic text-[28px] text-center">
                      Join today and receive a $10 welcome reward
                    </strong>{' '}
                    plus ongoing rewards for every $1 you spend
                  </h5>
                  <div className="flex justify-center mb-1">
                    <Link
                      href="/gb-rewards/"
                      className="button-black-outline bg-[#F1F2ED] uppercase text-xs text-black py-2 px-12"
                    >
                      Join Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rewards;
