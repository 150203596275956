import { useState } from 'react';

import { decodeEntities } from '@wordpress/html-entities';
import { stripHtml } from 'string-strip-html';

import { useSiteContext } from '../../contexts/SiteContext';
import useLoginMutation from './mutations/useLoginMutation';
import useSafeDispatch from './useSafeDispatch';
import { useViewerQuery } from './queries/useViewerQuery';
import useUserDetails from './useUserDetails';

const errorCodes = {
    invalid_username:
        'Invalid username or password. Please check it and try again.',
    invalid_email:
        'Invalid username or password. Please check it and try again.',
    incorrect_password:
        'Invalid username or password. Please check it and try again.',
    empty_username: 'Please provide your username.',
    empty_password: 'Please provide your password.',
};

const useAuth = () => {
    const { isLoggedIn, setIsLoggedIn } = useSiteContext();
    const [error, setError] = useState(null);
    const [status, setStatus] = useState('idle');
    const { loginMutation } = useLoginMutation();

    const { setUserDetails } = useUserDetails();
    const {
        data: viewer,
        refetch: refetchViewer,
        loading: loadingViewer,
    } = useViewerQuery();

    const onLoginSuccess = useSafeDispatch((response: any) => {
        setIsLoggedIn(true);
        setStatus('resolved');
        setUserDetails(response.data.login.user);
    });

    const onError = useSafeDispatch((errors: any) => {
        setError(
            errorCodes[errors.message] ||
                `${stripHtml(decodeEntities(errors.message)).result}`
        );
        setStatus('resolved');
    });

    const login = (username: string, password: string) => {
        setError(null);
        setStatus('resolving');

        return loginMutation(username, password)
            .then(onLoginSuccess)
            .catch(onError);
    };

    return {
        login,
        isLoggedIn,
        refetchViewer,
        loadingViewer,
        viewer,
        error,
        status,
    };
};

export default useAuth;
