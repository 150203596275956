import * as React from "react";

const SvgGbHalfStar = (props) => (
	<svg
		width={20}
		height={21}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.05 1.15v13.7l-.05-.037-5.878 4.27 2.245-6.909L.49 7.904h7.266L10 .994l.05.156Z"
			fill="#AF8C5A"
		/>
		<path
			d="m10 2.612 1.77 5.446.112.346h6.09l-4.633 3.366-.294.213.112.346 1.77 5.446-4.633-3.366-.294-.214-.294.214-4.633 3.366 1.77-5.446.112-.346-.294-.213-4.633-3.366h6.09l.112-.346L10 2.612Z"
			stroke="#AF8C5A"
		/>
	</svg>
);

export default SvgGbHalfStar;
