import React from 'react';
type Props = {
    className?: string;
};
const GooglePay = ({ className }: Props) => {
    return (
        <svg
            width="83"
            height="31"
            viewBox="0 0 83 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M39.3906 15.05V24.022H36.3424V1.87474H44.4267C46.3767 1.83942 48.2511 2.52821 49.671 3.79982C51.091 4.98312 51.8861 6.69626 51.8483 8.46239C51.8861 10.2462 51.091 11.9593 49.671 13.1603C48.2511 14.4142 46.5093 15.05 44.4456 15.05H39.3906ZM39.3906 4.59457V12.3302H44.5024C45.6383 12.3655 46.7364 11.9417 47.5127 11.1822C49.122 9.734 49.1409 7.34973 47.5884 5.84852L47.5127 5.77788C46.7364 5.00078 45.6383 4.57691 44.5024 4.61223L39.3906 4.59457ZM58.8723 8.37408C61.1253 8.37408 62.9049 8.93924 64.2113 10.0519C65.5177 11.1822 66.1614 12.7188 66.1614 14.6792V24.0043H63.2457V21.9026H63.1132C61.8447 23.6334 60.1786 24.4988 58.0771 24.4988C56.2974 24.4988 54.7828 24.0043 53.5901 23.0153C52.4163 22.0969 51.7536 20.737 51.7915 19.3064C51.7915 17.7345 52.4352 16.4983 53.7037 15.5622C54.9722 14.6262 56.6761 14.167 58.7966 14.167C60.6141 14.167 62.0908 14.4849 63.2647 15.0854V14.4319C63.2647 13.4605 62.8103 12.5421 62.0151 11.924C61.201 11.2529 60.1597 10.882 59.0805 10.882C57.3766 10.882 56.0324 11.5531 55.029 12.8954L52.3405 11.3235C53.7983 9.36311 55.9756 8.37408 58.8723 8.37408ZM54.9343 19.377C54.9343 20.1188 55.313 20.8076 55.9377 21.2315C56.6193 21.726 57.4523 21.9909 58.3043 21.9732C59.5917 21.9732 60.8224 21.4964 61.7311 20.6487C62.7346 19.7656 63.2457 18.7236 63.2457 17.5226C62.2991 16.8162 60.9738 16.4629 59.2699 16.4629C58.0393 16.4629 56.998 16.7455 56.1649 17.293C55.3508 17.8582 54.9343 18.547 54.9343 19.377ZM82.8979 8.8686L72.7121 30.6979H69.5693L73.3558 23.0682L66.6536 8.88626H69.9668L74.7947 19.7656H74.8704L79.5846 8.88626L82.8979 8.8686Z"
                fill="white"
            />
            <path
                d="M27.0653 13.1073C27.0653 12.2419 26.9896 11.3765 26.8381 10.5287H13.9829V15.4209H21.3477C21.0447 16.9928 20.0602 18.4057 18.6214 19.2887V22.4677H23.0137C25.5886 20.2601 27.0653 16.9928 27.0653 13.1073Z"
                fill="#4285F4"
            />
            <path
                d="M13.9826 25.5408C17.6556 25.5408 20.7607 24.4105 23.0137 22.4677L18.6214 19.2887C17.3907 20.0658 15.8191 20.5074 13.9826 20.5074C10.4233 20.5074 7.413 18.2644 6.33384 15.262H1.80893V18.547C4.11872 22.821 8.83295 25.5408 13.9826 25.5408Z"
                fill="#34A853"
            />
            <path
                d="M6.33384 15.262C5.76586 13.6901 5.76614 11.977 6.33412 10.3875V7.12015H1.80921C0.847748 8.888 0.34668 10.8424 0.34668 12.8248C0.34668 14.8071 0.847748 16.7615 1.80921 18.5294L6.33384 15.262Z"
                fill="#FBBC04"
            />
            <path
                d="M13.9827 5.14215C15.9327 5.10683 17.8071 5.79562 19.2081 7.04957L23.0893 3.429C20.628 1.25666 17.3716 0.0733561 13.9827 0.108679C8.83296 0.108679 4.119 2.82846 1.80921 7.12015L6.33384 10.4052C7.413 7.38514 10.4233 5.14215 13.9827 5.14215Z"
                fill="#EA4335"
            />
        </svg>
    );
};

export default GooglePay;
