import * as React from "react";

const SvgGbClose = (props) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.5 1.86 14.64 0 8.5 6.14 2.36 0 .5 1.86 6.64 8 .5 14.14 2.36 16 8.5 9.86 14.64 16l1.86-1.86L10.36 8l6.14-6.14Z"
      fill="#B18D56"
    />
  </svg>
);

export default SvgGbClose;
