import React from 'react';
import Footer from './footer';
import MainHeader from './header/Main';

export default function Layout({ children }) {
  return (
    <div className="bg-[#FAFAF6]">
      <MainHeader />
      {children}
      <Footer />
    </div>
  );
}
