const TS_CONFIG = {
    server: {
        apiKey: process.env.NEXT_PUBLIC_TS_APIKEY as string, // Be sure to use an API key that only allows search operations
        nodes: [
            {
                host: process.env.NEXT_PUBLIC_TS_HOST as string,
                path: '', // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
                port: 443,
                protocol: 'https',
            },
        ],
    },
    collectionNames: {
        product: `product-${process.env.NEXT_PUBLIC_TS_STORE_ID}`,
        taxonomy: `taxonomy-${process.env.NEXT_PUBLIC_TS_STORE_ID}`,
        page: `page-${process.env.NEXT_PUBLIC_TS_STORE_ID}`,
        siteInfo: `site_info-${process.env.NEXT_PUBLIC_TS_STORE_ID}`,
    },
};

export default TS_CONFIG;
