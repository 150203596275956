import '@/styles/globals.scss';
import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import client from '../utils/ApolloClient';

import { SiteContextProvider } from '../contexts/SiteContext';
import Layout from '../components/layout/Layout';
import TagManager from 'react-gtm-module';
import Maintenance from './maintenance';

function MyApp({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-K7D9ZTX' });
  }, []);

  if (process.env.NEXT_PUBLIC_MAINTENANCE === 'true') {
    return <Maintenance />;
  }

  return (
    <ApolloProvider client={client}>
      <SiteContextProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </SiteContextProvider>
    </ApolloProvider>
  );
}

export default MyApp;
