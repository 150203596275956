import * as React from "react";

const SvgGbChevronDown = (props) => (
	<svg
		width={props.width ? props.width : 12}
		height={props.height ? props.height : 9}
		viewBox="0 0 12 9"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path d="m.16.31 5.532 8.43 5.69-8.43H.159Z" fill={props.fillColor ? props.fillColor : "#000"} />
	</svg>
);

export default SvgGbChevronDown;
