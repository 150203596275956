import React, { MouseEvent, TouchEvent, UIEvent, useState } from 'react';

import { useToggle } from 'usehooks-ts';
import Img from 'next/image';

import { GbImagePlaceholder } from '../icons';
import isEmpty from 'lodash/isEmpty';
import imageDataBlurUrl from '../../utils/image';
import { ImageProps } from 'next/image';
import { cn } from '../../utils';

export interface FutureImageProps extends ImageProps {
  showDefault?: Boolean;
  blur?: Boolean;
  className?: string;
  fill?: boolean;
  hasZoomHover?: boolean;
}

const FutureImage = (props: FutureImageProps) => {
  const {
    width,
    height,
    src,
    showDefault = true,
    blur = true,
    alt,
    className,
    hasZoomHover = false,
    ...rest
  } = props;
  const [isLoading, setLoading] = useState(true);

  const [state, setState] = useState({
    backgroundImage: `url(${src})`,
    backgroundPosition: '0% 0%',
  });
  const [isClicked, toggleClicked, setIsClicked] = useToggle();

  const handleMouseMove = (e: MouseEvent) => {
    const { left, top, width, height } = (
      e.target as HTMLElement
    ).getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setState({ ...state, backgroundPosition: `${x}% ${y}%` });
  };

  const handleTouchMove = (e: UIEvent & TouchEvent) => {
    const { left, top, width, height } = (
      e.target as HTMLElement
    ).getBoundingClientRect();
    const x = ((e.touches[0].clientX - left) / width) * 100;
    const y = ((e.touches[0].clientY - top) / height) * 100;
    setState({ ...state, backgroundPosition: `${x}% ${y}%` });
  };

  if (isEmpty(src) && showDefault) {
    return <GbImagePlaceholder width="100%" height="100%" />;
  }

  let attributes = {
    src: src,
    width: width,
    height: height,
    alt: `${alt}`,
    className: className,
    ...rest,
  };

  const zoomAttributes = {
    ...attributes,
    className: cn(className, 'pointer-events-none duration-1000 ease-linear', {
      'opacity-0 md:opacity-100 lg:opacity-100': isClicked,
      'opacity-100 group-hover:opacity-0': !isClicked,
    }),
  };

  if (blur) {
    attributes = {
      ...attributes,
      placeholder: 'blur',
      blurDataURL: imageDataBlurUrl(10, 10),
      onLoadingComplete: () => setLoading(false),
      className: cn(className),
    };
  }
  return (
    <>
      {hasZoomHover ? (
        <figure
          onMouseMove={handleMouseMove}
          onTouchMove={handleTouchMove}
          onClick={toggleClicked}
          onMouseOut={() => setIsClicked(false)}
          onTouchStart={() => setIsClicked(true)}
          onTouchEnd={() => setIsClicked(false)}
          style={state}
          className={cn('group bg-no-repeat w-full h-full', {
            'cursor-zoom-in': isClicked,
            'cursor-zoom-out': !isClicked,
          })}
        >
          <Img {...zoomAttributes} />
        </figure>
      ) : (
        <Img {...attributes} />
      )}
    </>
  );
};

export default FutureImage;
