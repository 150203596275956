import { useMemo } from "react";
import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, } from '@apollo/client';
import { relayStylePagination } from "@apollo/client/utilities";

/**
 * Middleware operation
 * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
 */
export const middleware = new ApolloLink((operation, forward) => {
	/**
	 * If session data exist in local storage, set value as session header.
	 */
	const session = (process.browser) ? localStorage.getItem("woo-session") : null;

	if (session) {
		operation.setContext(({ headers = {} }) => ({
			headers: {
				"woocommerce-session": `Session ${session}`
			}
		}));
	}

	return forward(operation);

});

/**
 * Afterware operation.
 *
 * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
 */
export const afterware = new ApolloLink((operation, forward) => {

	return forward(operation).map(response => {

		if (!process.browser) {
			return response;
		}

		/**
		 * Check for session header and update session in local storage accordingly.
		 */
		const context = operation.getContext();
		const { response: { headers } } = context;
		const session = headers.get("woocommerce-session");

		if (session) {
			// Remove session data if session destroyed.
			if ("false" === session) {

				localStorage.removeItem("woo-session");

				// Update session new data if changed.
			} else if (localStorage.getItem("woo-session") !== session) {

				localStorage.setItem("woo-session", headers.get("woocommerce-session"));

			}
		}

		return response;

	});
});

let apolloClient;

// Apollo GraphQL client.
const client = new ApolloClient({
	ssrMode: typeof window === "undefined", // set to true for SSR
	link: middleware.concat(afterware.concat(createHttpLink({
		uri: `${process.env.NEXT_PUBLIC_WORDPRESS_SITE_URL}/graphql`,
		credentials: 'include',
		fetch: fetch
	}))),
	cache: new InMemoryCache(),
	defaultOptions: {
		watchQuery: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'ignore',
		},
		query: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all',
		},
	}
});

export function initializeApollo(initialState = null) {
	const _apolloClient = apolloClient ?? client;

	// If your page has Next.js data fetching methods that use Apollo Client,
	// the initial state gets hydrated here
	if (initialState) {
		// Get existing cache, loaded during client side data fetching
		const existingCache = _apolloClient.extract();

		// Restore the cache using the data passed from
		// getStaticProps/getServerSideProps combined with the existing cached data
		_apolloClient.cache.restore({ ...existingCache, ...initialState });
	}

	// For SSG and SSR always create a new Apollo Client
	if (typeof window === "undefined") return _apolloClient;

	// Create the Apollo Client once in the client
	if (!apolloClient) apolloClient = _apolloClient;
	return _apolloClient;
}

export function useApollo(initialState) {
	const store = useMemo(() => initializeApollo(initialState), [initialState]);
	return store;
}

export default client;