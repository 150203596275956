import * as React from "react";

const SvgGbFilter = (props) => (
	<svg
		width={15}
		height={17}
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M.372 1.539a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v3a1 1 0 0 1-.293.707L9.372 9.953v3.586a1 1 0 0 1-.293.707l-2 2a1 1 0 0 1-1.707-.707V9.953L.665 5.246a1 1 0 0 1-.293-.707v-3Z"
			fill="#000"
		/>
	</svg>
);

export default SvgGbFilter;
