/**
 * External dependencies
 */
import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

export const useSafeDispatch = (dispatch: any) => {
    const mounted = useRef(false);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return useCallback(
        (...args) => (mounted.current ? dispatch(...args) : void 0),
        [dispatch]
    );
};

export default useSafeDispatch;
