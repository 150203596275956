import * as React from "react";

const SvgGooglePayLogo = (props) => (
  <svg
    width={55}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.401 10.168v5.866h-1.993V1.554h5.286a4.998 4.998 0 0 1 3.429 1.258c.928.774 1.448 1.894 1.423 3.049.025 1.166-.495 2.286-1.423 3.071-.929.82-2.067 1.236-3.417 1.236h-3.305Zm0-6.836V8.39h3.342a2.705 2.705 0 0 0 1.969-.75c1.052-.948 1.064-2.507.05-3.488l-.05-.046a2.668 2.668 0 0 0-1.969-.762l-3.342-.012ZM39.14 5.803c1.473 0 2.636.37 3.49 1.097.854.74 1.275 1.744 1.275 3.025v6.097h-1.906v-1.374h-.087c-.829 1.132-1.918 1.698-3.292 1.698-1.164 0-2.154-.324-2.934-.97-.768-.6-1.2-1.49-1.176-2.425 0-1.028.42-1.836 1.25-2.448.83-.612 1.944-.912 3.33-.912 1.188 0 2.154.207 2.921.6v-.427c0-.635-.297-1.236-.817-1.64a3.02 3.02 0 0 0-1.918-.681c-1.114 0-1.993.439-2.65 1.316l-1.757-1.028c.953-1.281 2.377-1.928 4.27-1.928Zm-2.575 7.194c0 .485.247.935.656 1.212.446.324.99.497 1.547.485.842 0 1.646-.311 2.24-.866.657-.577.991-1.258.991-2.043-.619-.462-1.485-.693-2.6-.693-.804 0-1.485.184-2.03.542-.532.37-.804.82-.804 1.363Zm18.283-6.87-6.66 14.272h-2.055l2.476-4.989-4.382-9.272h2.166l3.157 7.113h.05l3.082-7.113 2.166-.012Z"
      fill="#000"
    />
    <path
      d="M18.343 8.898c0-.566-.05-1.132-.149-1.686H9.79v3.198h4.816c-.198 1.028-.842 1.952-1.783 2.53v2.078h2.872c1.683-1.444 2.649-3.58 2.649-6.12Z"
      fill="#4285F4"
    />
    <path
      d="M9.79 17.027c2.4 0 4.43-.74 5.904-2.01l-2.872-2.078c-.804.508-1.832.797-3.033.797-2.327 0-4.295-1.466-5-3.43H1.83v2.148c1.51 2.795 4.592 4.573 7.96 4.573Z"
      fill="#34A853"
    />
    <path
      d="M4.788 10.306a4.698 4.698 0 0 1 0-3.187V4.983H1.83a7.797 7.797 0 0 0-.956 3.73c0 1.296.328 2.574.956 3.73l2.958-2.136Z"
      fill="#FBBC04"
    />
    <path
      d="M9.79 3.69a4.99 4.99 0 0 1 3.416 1.247l2.537-2.367C14.134 1.15 12.005.376 9.79.4 6.422.4 3.34 2.176 1.83 4.982l2.958 2.148C5.494 5.157 7.462 3.69 9.79 3.69Z"
      fill="#EA4335"
    />
  </svg>
);

export default SvgGooglePayLogo;
