import * as React from "react";

const SvgApplePayLogo = (props) => (
  <svg
    width={48}
    height={21}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.637 2.978c.555-.695.932-1.627.833-2.58C9.657.44 8.665.936 8.09 1.63c-.515.595-.971 1.567-.852 2.48.912.079 1.823-.457 2.398-1.131ZM10.46 4.287c-1.325-.08-2.452.751-3.084.751-.633 0-1.602-.712-2.65-.692-1.363.02-2.629.79-3.32 2.017-1.424 2.453-.376 6.092 1.008 8.09.672.989 1.482 2.077 2.55 2.038 1.008-.04 1.403-.653 2.63-.653 1.224 0 1.58.653 2.648.633 1.107-.02 1.799-.989 2.471-1.978.771-1.127 1.087-2.215 1.107-2.275-.02-.02-2.135-.831-2.155-3.264-.02-2.037 1.66-3.006 1.74-3.066-.95-1.403-2.432-1.562-2.946-1.601ZM21.993 1.53c2.88 0 4.884 1.984 4.884 4.874 0 2.9-2.046 4.894-4.956 4.894h-3.188v5.07H16.43V1.53h5.563Zm-3.26 7.835h2.643c2.005 0 3.146-1.08 3.146-2.95 0-1.872-1.14-2.942-3.136-2.942h-2.653v5.892ZM27.479 13.293c0-1.892 1.45-3.054 4.02-3.198l2.962-.174v-.833c0-1.203-.813-1.923-2.17-1.923-1.285 0-2.087.617-2.283 1.583h-2.097c.123-1.953 1.789-3.393 4.462-3.393 2.622 0 4.298 1.388 4.298 3.558v7.455h-2.128v-1.78h-.051c-.627 1.204-1.995 1.965-3.414 1.965-2.118 0-3.6-1.316-3.6-3.26Zm6.982-.976v-.854l-2.664.165c-1.326.092-2.077.678-2.077 1.604 0 .946.782 1.563 1.975 1.563 1.552 0 2.766-1.07 2.766-2.479ZM38.68 20.347v-1.8c.165.042.535.042.72.042 1.029 0 1.584-.432 1.923-1.542 0-.021.196-.659.196-.669L37.612 5.55h2.405l2.736 8.802h.04L45.53 5.55h2.344l-4.051 11.383c-.925 2.622-1.995 3.466-4.236 3.466-.185 0-.74-.021-.905-.052Z"
      fill="#000"
    />
  </svg>
);

export default SvgApplePayLogo;
