import * as React from "react";

const SvgGbPlus = (props) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M16.626 7h-7V0h-2v7h-7v2h7v7h2V9h7V7Z" fill="#90B580" />
  </svg>
);

export default SvgGbPlus;
