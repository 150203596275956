import * as React from "react";

const SvgGbPhone = (props) => (
  <svg
    width={19}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m17.866 12.72-4.023-1.688a.88.88 0 0 0-1.005.243l-1.782 2.13a13.176 13.176 0 0 1-6.365-6.23l2.177-1.743a.825.825 0 0 0 .248-.984L5.392.51a.857.857 0 0 0-.404-.418.885.885 0 0 0-.584-.07L.668.864a.865.865 0 0 0-.48.297.833.833 0 0 0-.188.525C0 10.705 7.468 18 16.667 18a.874.874 0 0 0 .537-.183.841.841 0 0 0 .304-.47l.862-3.657a.838.838 0 0 0-.073-.574.865.865 0 0 0-.43-.396Z"
      fill="#B18D56"
    />
  </svg>
);

export default SvgGbPhone;
