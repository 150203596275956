import { Client } from 'typesense';
import TS_CONFIG from './config';

const c = new Client(TS_CONFIG.server);

const disableCache = { cacheSearchResultsForSeconds: 0 };

export const unCacheTSClient = new Client({
    ...TS_CONFIG.server,
    ...disableCache,
});

export default c;
