import { gql } from "@apollo/client";
const CART_FIELDS = `
cart {
    contents {
      nodes {
        key
        product {
          node {
            id
            productId: databaseId
            name
            description
            type
            onSale
            slug
            averageRating
            reviewCount
            image {
              id
              sourceUrl
              srcSet
              altText
              title
            }
            galleryImages {
              nodes {
                id
                sourceUrl
                srcSet
                altText
                title
              }
            }
          }
        }
        variation {
          node {
            id
            variationId: databaseId
            name
            description
            type
            onSale
            price
            regularPrice
            salePrice
            image {
              id
              sourceUrl
              srcSet
              altText
              title
            }
          }
          attributes {
            id
            name
            value
          }
        }
        quantity
        total
        subtotal
        subtotalTax
      }
    }
    subtotal
    subtotalTax
    shippingTax
    shippingTotal
    total
    totalTax
    feeTax
    feeTotal
    discountTax
    discountTotal
  }
`

const GET_CART = gql`
query GET_CART {${CART_FIELDS}}
`;


export const CUSTOM_GET_CART = gql`
query GET_CART($cartKey: String) {
	blzGetCart(cart_key: $cartKey)
}
`;
export default GET_CART;

export const REMOVE_CART_ITEM = gql`
mutation RemoveCartItem ($cartKey: [ID]) {
	removeItemsFromCart(input: {keys: $cartKey}) {
		${CART_FIELDS}
	}
}
`
