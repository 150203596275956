/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */
export const getNumberFromCurrency = (string) => {

	return Number(string.replace(/[^0-9.-]+/g,""));

};

/**
 * Returns cart data in the required format.
 * @param {String} data Cart data
 */
export const getFormattedCart = (data) => {

	let formattedCart = null;

	if (undefined === data || !data.cart.contents.nodes.length) {
		return formattedCart;
	}

	const givenProducts = data.cart.contents.nodes;

	// Create an empty object.
	formattedCart = {};
	formattedCart.products = [];
	let totalProductsCount = 0;

	for (let i = 0; i < givenProducts.length; i++) {
		const givenProduct = givenProducts?.[i]?.product?.node;
		const product = {};
		const total = getNumberFromCurrency(givenProducts[i].total.replace('$',''));

		product.productId = givenProduct?.productId ?? '';
		product.cartKey = givenProducts?.[i]?.key ?? '';
		product.name = givenProduct?.name ?? '';
		product.qty = givenProducts?.[i]?.quantity;
		product.price = total / product?.qty;
		product.totalPrice = givenProducts?.[i]?.total ?? '';
		product.image = {
			sourceUrl: givenProduct?.image?.sourceUrl ?? '',
			srcSet: givenProduct?.image?.srcSet ?? '',
			title: givenProduct?.image?.title ?? '',
			altText: givenProduct?.image?.altText ?? ''
		};

		totalProductsCount += givenProducts?.[i]?.quantity;

		// Push each item into the products array.
		formattedCart.products.push(product);
	}

	formattedCart.totalProductsCount = totalProductsCount;
	formattedCart.totalProductsPrice = data?.cart?.total ?? '';

	return formattedCart;

};

export const setCartLocalStorage = (newCart) => {
	return localStorage.setItem('woo-next-cart', JSON.stringify(newCart));
}

export const getCartLocalStorage = () => {
	return localStorage.getItem('woo-next-cart');
}

export const removeCartLocalStorage = () => {
	return localStorage.removeItem('woo-next-cart');
}

export const setCartKey = (newCart) => {
	return localStorage.setItem('woo-cart-key', JSON.stringify(newCart));
}

export const getCartKey = () => {
	return localStorage.getItem('woo-cart-key');
}

export const removeCartKey = () => {
	return localStorage.removeItem('woo-cart-key');
}