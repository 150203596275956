import * as React from "react";

const SvgGbTruck = (props) => (
  <svg
    width={22}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.313 14.727c0-.443-.161-.826-.483-1.15a1.556 1.556 0 0 0-1.143-.486c-.44 0-.82.162-1.142.486a1.578 1.578 0 0 0-.482 1.15c0 .444.16.827.482 1.15.322.325.702.487 1.143.487.44 0 .82-.162 1.142-.486.322-.324.482-.707.482-1.15ZM2.438 8.182h4.874V4.909H5.308a.379.379 0 0 0-.28.115L2.552 7.517a.384.384 0 0 0-.115.281v.384Zm16.25 6.545c0-.443-.161-.826-.483-1.15a1.556 1.556 0 0 0-1.142-.486c-.44 0-.822.162-1.143.486a1.578 1.578 0 0 0-.482 1.15c0 .444.16.827.482 1.15.321.325.702.487 1.143.487.44 0 .82-.162 1.142-.486.322-.324.483-.707.483-1.15ZM21.938.818V13.91c0 .128-.017.24-.051.339a.484.484 0 0 1-.172.236c-.08.06-.15.109-.21.147a.695.695 0 0 1-.297.077l-.286.026a3.87 3.87 0 0 1-.324 0 20.1 20.1 0 0 0-.285-.007c0 .904-.318 1.675-.953 2.314a3.12 3.12 0 0 1-2.297.959 3.12 3.12 0 0 1-2.298-.959 3.164 3.164 0 0 1-.953-2.314H8.939c0 .904-.318 1.675-.953 2.314A3.12 3.12 0 0 1 5.687 18a3.12 3.12 0 0 1-2.297-.959 3.164 3.164 0 0 1-.953-2.314h-.812l-.286.007a3.87 3.87 0 0 1-.323 0l-.286-.026a.695.695 0 0 1-.298-.077 4.168 4.168 0 0 1-.21-.147.484.484 0 0 1-.171-.236A1.036 1.036 0 0 1 0 13.909c0-.222.08-.413.241-.575a.778.778 0 0 1 .571-.243V9l-.006-.447a8.886 8.886 0 0 1 0-.486c.004-.094.015-.241.032-.441s.044-.358.082-.473c.038-.115.098-.245.178-.39.08-.145.176-.273.286-.384l2.513-2.531a1.94 1.94 0 0 1 .642-.41c.266-.11.514-.165.742-.165h2.032V.818c0-.221.08-.413.24-.575A.778.778 0 0 1 8.126 0h13c.22 0 .41.081.571.243.161.162.241.354.241.575Z"
      fill="#B18D56"
    />
  </svg>
);

export default SvgGbTruck;
