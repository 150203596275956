import React, { useEffect, useState } from 'react';
import { useSiteContext } from '../../../contexts/SiteContext';
import { GbBars, GbPhone, GbTruck, GbBasket } from '../../../components/icons';

type Props = {};

const CartBasketIcon = (props: Props) => {
  const {
    miniCartState: [, setMiniCartIsOpen],
    cart,
  } = useSiteContext();

  const [userCart, setUserCart] = useState<any>(null);
  useEffect(() => setUserCart(cart), [cart]);

  return (
    <button
      className="relative"
      aria-label="Cart"
      onClick={(e) => setMiniCartIsOpen((prev: boolean) => !prev)}
    >
      {userCart && userCart.totalProductsCount > 0 ? (
        <span className="-top-3 -right-3 absolute bg-brand-primary rounded-full text-2.5 text-white min-w-[20px] min-h-[20px] flex items-center justify-center">
          {userCart.totalProductsCount}
        </span>
      ) : null}
      <GbBasket />
    </button>
  );
};

export default CartBasketIcon;
