import React from 'react';
import { isEmpty } from 'lodash';
import parse from 'html-react-parser';
import TSSiteInfo from '../../utils/typesense/siteInfo';

type Props = {};

const SiteMessage = (props: Props) => {
    const siteMsg = TSSiteInfo.getSiteMessage();
	// const siteMsg = `
	// <p style="text-align: center;"><strong><span style="color: rgb(0, 0, 0);">WE'RE STILL TAKING ORDERS! SAME DAY DISPATCH BEFORE 3.00pm</span></strong></p>
	// <p style="text-align: center;"><strong><span style="color: rgb(255, 0, 0);"><a href="https://gourmetbasket.com.au/gift-baskets-on-sale/">HAMPERS ON SALE! Save up to 25% - CLICK HERE</a></span></strong></p>
	// <p style="text-align: center;"><strong><span>Note: Some Courier deliveries may be delayed due to Christmas volumes</a></span></strong></p>
	// `;
    const clssName =
        'text-red-600 text-black text-xs md:text-sm tracking-wider';
    return !isEmpty(siteMsg) ? <>{parse(siteMsg)}</> : null;
};

export default SiteMessage;
