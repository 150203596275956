import React from 'react';
type Props = {
    className?: string;
};
const MasterCard = ({ className }: Props) => {
    return (
        <svg
            width="51"
            height="31"
            viewBox="0 0 51 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M32.002 3.37798H18.6175V27.4278H32.002V3.37798Z"
                fill="#FF5F00"
            />
            <path
                d="M19.4674 15.4029C19.4653 13.0867 19.9902 10.8004 21.0024 8.71709C22.0146 6.63375 23.4875 4.80798 25.3098 3.37799C23.0532 1.60446 20.3431 0.501535 17.4893 0.195264C14.6355 -0.111007 11.7531 0.391727 9.1715 1.64603C6.58992 2.90032 4.41336 4.85558 2.89059 7.28828C1.36783 9.72099 0.560303 12.533 0.560303 15.4029C0.560303 18.2728 1.36783 21.0848 2.89059 23.5175C4.41336 25.9502 6.58992 27.9055 9.1715 29.1598C11.7531 30.4141 14.6355 30.9168 17.4893 30.6106C20.3431 30.3043 23.0532 29.2014 25.3098 27.4278C23.4876 25.9978 22.0146 24.1721 21.0024 22.0887C19.9902 20.0054 19.4653 17.7191 19.4674 15.4029Z"
                fill="#EB001B"
            />
            <path
                d="M50.0585 15.4029C50.0586 18.2728 49.2511 21.0848 47.7284 23.5175C46.2058 25.9502 44.0293 27.9054 41.4478 29.1598C38.8662 30.4141 35.9838 30.9168 33.1301 30.6106C30.2763 30.3043 27.5663 29.2014 25.3098 27.4278C27.1304 25.9964 28.6022 24.1703 29.6142 22.0873C30.6263 20.0043 31.1521 17.7187 31.1521 15.4029C31.1521 13.0871 30.6263 10.8015 29.6142 8.7185C28.6022 6.63549 27.1304 4.80943 25.3098 3.37799C27.5663 1.60446 30.2763 0.501527 33.1301 0.19526C35.9838 -0.111007 38.8662 0.391749 41.4478 1.64606C44.0293 2.90037 46.2058 4.85562 47.7284 7.28833C49.2511 9.72104 50.0586 12.533 50.0585 15.4029Z"
                fill="#F79E1B"
            />
            <path
                d="M48.5991 24.8806V24.3882H48.7976V24.2879H48.292V24.3882H48.4906V24.8806H48.5991ZM49.5808 24.8806V24.2869H49.4258L49.2476 24.6953L49.0692 24.2869H48.9141V24.8806H49.0236V24.4328L49.1908 24.8189H49.3043L49.4715 24.4318V24.8806H49.5808Z"
                fill="#F79E1B"
            />
        </svg>
    );
};

export default MasterCard;
