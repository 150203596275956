import React from 'react';
type Props = {
    className?: string;
};
const Eft = ({ className }: Props) => {
    return (
        <svg
            width="41"
            height="31"
            viewBox="0 0 41 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M0.273193 1.98885C0.273193 0.950035 1.11532 0.10791 2.15413 0.10791H38.7734C39.8122 0.10791 40.6543 0.950035 40.6543 1.98885V28.817C40.6543 29.8558 39.8122 30.6979 38.7734 30.6979H2.15413C1.11532 30.6979 0.273193 29.8558 0.273193 28.817V1.98885Z"
                fill="#034DA2"
            />
            <path
                d="M8.49823 14.7694C8.74513 13.5797 9.73275 12.9512 10.9224 12.9512C12.0222 12.9512 13.0323 13.6695 13.2343 14.7694H8.49823ZM17.0277 16.6997C17.0277 12.7717 14.7157 10.3026 10.7204 10.3026C6.9719 10.3026 4.34574 12.6146 4.34574 16.4528C4.34574 20.4257 7.19636 22.5805 11.0122 22.5805C12.3365 22.5805 13.6383 22.2663 14.6933 21.6154C15.7482 20.9644 16.5563 19.9768 16.8705 18.6301H12.9425C12.4936 19.3932 11.8651 19.685 10.9897 19.685C9.30628 19.685 8.43089 18.7872 8.43089 17.1262H17.0277V16.6997Z"
                fill="white"
            />
            <path
                d="M22.8598 14.0511H25.7104V10.6842H22.8598V8.82121C22.8598 7.51934 23.2189 6.89086 24.2739 6.89086C24.8126 6.89086 25.2391 7.07043 25.7104 7.27244V3.74844C25.1493 3.45664 24.5881 3.34441 23.9597 3.34441C22.4109 3.34441 20.9519 3.99534 19.9419 5.18497C18.8196 6.46439 18.7747 7.63157 18.7747 9.22523V10.6842H17.3381V14.0511H18.7747V22.1989H22.8598V14.0511Z"
                fill="white"
            />
            <path
                d="M31.8894 14.0511H34.2013V10.6842H31.8894V7.22755H27.8042V10.6842H26.4799V14.0511H27.8042V22.1989H31.8894V14.0511Z"
                fill="white"
            />
            <path
                d="M5.35097 27.8994H6.35092V26.624H6.91722C7.78962 26.624 8.27939 26.2261 8.27939 25.3179C8.27939 24.4353 7.70799 24.0527 6.88151 24.0527H5.35097V27.8994ZM6.35092 24.8588H6.51928C6.90701 24.8588 7.23863 24.8639 7.23863 25.3486C7.23863 25.8179 6.88151 25.8179 6.51928 25.8179H6.35092V24.8588Z"
                fill="white"
            />
            <path
                d="M10.7023 27.2311L10.9523 27.8994H12.0186L10.5697 24.0527H9.47787L7.99836 27.8994H9.05953L9.32482 27.2311H10.7023ZM10.4319 26.4658H9.60031L10.0136 25.2414H10.0238L10.4319 26.4658Z"
                fill="white"
            />
            <path
                d="M12.7131 26.0526V27.8994H13.713V26.0526L15.1313 24.0527H13.9324L13.2131 25.0986L12.4937 24.0527H11.2948L12.7131 26.0526Z"
                fill="white"
            />
            <path
                d="M15.1306 27.8994H16.1255L16.4469 25.6853H16.4571L17.3397 27.8994H17.7377L18.6611 25.6853H18.6713L18.9519 27.8994H19.9518L19.3702 24.0527H18.3754L17.5489 26.1087L16.7683 24.0527H15.7837L15.1306 27.8994Z"
                fill="white"
            />
            <path
                d="M22.566 24.8996V24.0527H20.3773V27.8994H22.566V27.0525H21.3773V26.3893H22.5047V25.5424H21.3773V24.8996H22.566Z"
                fill="white"
            />
            <path
                d="M23.1823 27.8994H24.1822V25.5424H24.1924L26.024 27.8994H27.0239V24.0527H26.024V26.4046H26.0138L24.1822 24.0527H23.1823V27.8994Z"
                fill="white"
            />
            <path
                d="M29.2068 24.8996H30.0333V24.0527H27.3803V24.8996H28.2068V27.8994H29.2068V24.8996Z"
                fill="white"
            />
            <path
                d="M32.9066 24.267C32.5648 24.0731 32.0903 23.9507 31.7026 23.9507C30.9322 23.9507 30.3761 24.4608 30.3761 25.2414C30.3761 25.9863 30.7894 26.1495 31.4169 26.3281C31.6311 26.3893 32.024 26.4862 32.024 26.7668C32.024 27.0372 31.774 27.1546 31.5393 27.1546C31.1975 27.1546 30.9016 26.976 30.6516 26.7566L30.2231 27.5627C30.6159 27.8382 31.0904 28.0015 31.575 28.0015C31.9628 28.0015 32.3658 27.8943 32.6668 27.6392C32.9729 27.3791 33.0647 26.9862 33.0647 26.6036C33.0647 25.9812 32.6515 25.7108 32.1158 25.5526L31.8607 25.4761C31.6873 25.42 31.4169 25.3332 31.4169 25.1088C31.4169 24.8945 31.6618 24.7874 31.8454 24.7874C32.0903 24.7874 32.325 24.8894 32.5087 25.0424L32.9066 24.267Z"
                fill="white"
            />
        </svg>
    );
};

export default Eft;
