import React from 'react';
type Props = {
    className?: string;
};
const ApplePay = ({ className }: Props) => {
    return (
        <svg
            width="73"
            height="31"
            viewBox="0 0 73 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M14.4876 4.05266C15.3366 2.99076 15.9127 1.56493 15.7608 0.10791C14.518 0.169708 13.0014 0.92783 12.1233 1.99057C11.335 2.90064 10.6372 4.38616 10.8191 5.7821C12.2142 5.90312 13.608 5.08477 14.4876 4.05266Z"
                fill="white"
            />
            <path
                d="M15.7449 6.05466C13.7189 5.93398 11.9963 7.20454 11.0287 7.20454C10.0607 7.20454 8.57906 6.11548 6.97658 6.14483C4.89084 6.17547 2.95552 7.35476 1.89709 9.23039C-0.279904 12.9826 1.32258 18.5484 3.4396 21.6043C4.46767 23.1162 5.70667 24.7809 7.33924 24.7211C8.88174 24.6605 9.48634 23.7223 11.3613 23.7223C13.2349 23.7223 13.7795 24.7211 15.4124 24.6908C17.1057 24.6605 18.1643 23.1782 19.1923 21.6648C20.3717 19.9414 20.8545 18.2772 20.8849 18.1859C20.8545 18.1556 17.6197 16.9147 17.5898 13.1937C17.5592 10.0781 20.1294 8.59608 20.2504 8.50418C18.799 6.35753 16.5312 6.11548 15.7449 6.05466Z"
                fill="white"
            />
            <path
                d="M33.3861 1.83816C37.7897 1.83816 40.856 4.87357 40.856 9.2929C40.856 13.728 37.7266 16.7792 33.2757 16.7792H28.4001V24.5327H24.8775V1.83815L33.3861 1.83816ZM28.4001 13.8223H32.442C35.509 13.8223 37.2545 12.1712 37.2545 9.30867C37.2545 6.44648 35.509 4.81077 32.4578 4.81077H28.4001V13.8223Z"
                fill="white"
            />
            <path
                d="M41.7764 19.8304C41.7764 16.9363 43.9939 15.1593 47.9261 14.939L52.4552 14.6718V13.398C52.4552 11.5578 51.2127 10.4569 49.1371 10.4569C47.1707 10.4569 45.9439 11.4003 45.6454 12.8789H42.4371C42.6258 9.89052 45.1734 7.68874 49.2627 7.68874C53.2731 7.68874 55.8365 9.81195 55.8365 13.1304V24.5327H52.5808V21.8119H52.5026C51.5433 23.6521 49.4514 24.8158 47.2811 24.8158C44.0413 24.8158 41.7764 22.8027 41.7764 19.8304ZM52.4552 18.3363V17.031L48.3817 17.2825C46.3529 17.4241 45.205 18.3206 45.205 19.736C45.205 21.1828 46.4002 22.1265 48.2246 22.1265C50.5993 22.1265 52.4552 20.4908 52.4552 18.3363Z"
                fill="white"
            />
            <path
                d="M58.9101 30.6193V27.867C59.1613 27.9298 59.7274 27.9298 60.0107 27.9298C61.5833 27.9298 62.4327 27.2693 62.9515 25.5708C62.9515 25.5393 63.2506 24.5643 63.2506 24.5485L57.2744 7.98755H60.9541L65.1381 21.4503H65.2005L69.3845 7.98755H72.9702L66.7732 25.3976C65.3583 29.4083 63.7226 30.6979 60.294 30.6979C60.0107 30.6979 59.1613 30.6664 58.9101 30.6193Z"
                fill="white"
            />
        </svg>
    );
};

export default ApplePay;
