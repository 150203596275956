/**
 * External dependencies
 */
import { gql, useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';

/**
 * Internal dependencies
 */
import { useSiteContext } from '../../../contexts/SiteContext';
import useSafeDispatch from '../useSafeDispatch';

const VIEWER = gql`
    query viewer {
        viewer {
            id
            email
            username
            roles {
                nodes {
                    displayName
                    isRestricted
                    name
                }
            }
        }
    }
`;

/**
 * Hook which gets details about the logged in user.
 */
export const useViewerQuery = () => {
    const { setIsLoggedIn, isLoggedIn } = useSiteContext();

    const onError = useSafeDispatch(() => {
        setIsLoggedIn(false);
    });

    const onCompleted = useSafeDispatch((theData: any) => {
        if (!theData?.viewer) {
            setIsLoggedIn(false);
        }
    });

    const [getViewer, { loading, error, data }] = useLazyQuery(VIEWER, {
        fetchPolicy: 'network-only',
        onError,
        onCompleted,
    });

    useEffect(() => {
        if (isLoggedIn) {
            getViewer();
        }
    }, [isLoggedIn, getViewer]);

    return {
        loading,
        error,
        data: data && data.viewer ? data.viewer : null,
        refetch: getViewer,
    };
};
