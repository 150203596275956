import isACookieProduct from '../isACookieProduct';

const useIsCartHasCookie = (cart: any) => {
    let hasCookie = false;
    const hasCartItems =
        cart !== null && cart?.products?.length > 0 ? true : false;
    if (hasCartItems) {
        const productCarts = cart.products;
        for (const cartProductIndex in productCarts) {
            const product = productCarts[cartProductIndex];

            if (isACookieProduct(product.name)) {
                hasCookie = true;
                break;
            }
        }
    }

    return hasCookie;
};

export default useIsCartHasCookie;
