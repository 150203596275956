import React from 'react';
type Props = {
    className?: string;
};

const Afterpay = ({ className }: Props) => {
    return (
        <svg
            width="160"
            height="31"
            viewBox="0 0 160 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
			className={className}
        >
            <path
                d="M156.926 6.28136L147.271 0.754406C144.058 -1.08791 140.04 1.22537 140.04 4.93771V5.56105C140.04 5.90735 140.22 6.22595 140.525 6.39217L142.783 7.68041C143.406 8.04057 144.182 7.58345 144.182 6.86315V5.38098C144.182 4.64682 144.972 4.18971 145.609 4.54986L150.042 7.09863L154.46 9.63355C155.098 9.9937 155.098 10.9218 154.46 11.2819L150.042 13.8169L145.609 16.3656C144.972 16.7258 144.182 16.2687 144.182 15.5345V14.8003C144.182 11.088 140.165 8.76087 136.951 10.617L127.297 16.144C124.069 18.0002 124.069 22.6683 127.297 24.5245L136.951 30.0514C140.165 31.8937 144.182 29.5804 144.182 25.8681V25.2448C144.182 24.8985 144.002 24.5799 143.697 24.4136L141.439 23.1254C140.816 22.7653 140.04 23.2224 140.04 23.9427V25.4248C140.04 26.159 139.251 26.6161 138.614 26.256L134.181 23.7072L129.762 21.1723C129.125 20.8121 129.125 19.884 129.762 19.5239L134.181 16.989L138.614 14.4402C139.251 14.08 140.04 14.5372 140.04 15.2713V16.0055C140.04 19.7178 144.058 22.045 147.271 20.1888L156.926 14.6618C160.154 12.7918 160.154 8.13753 156.926 6.28136Z"
                fill="#B2FCE4"
            />
            <path
                d="M125.482 7.19562L114.262 30.37H109.608L113.805 21.7125L107.197 7.19562H111.976L116.215 16.9197L120.841 7.19562H125.482Z"
                fill="#B2FCE4"
            />
            <path
                d="M13.2945 15.4237C13.2945 12.6533 11.286 10.714 8.8203 10.714C6.35464 10.714 4.3461 12.6949 4.3461 15.4237C4.3461 18.1249 6.35464 20.1334 8.8203 20.1334C11.286 20.1334 13.2945 18.1941 13.2945 15.4237ZM13.3361 23.6518V21.5186C12.1171 23.0008 10.3025 23.915 8.14155 23.915C3.62579 23.915 0.204346 20.2996 0.204346 15.4237C0.204346 10.5894 3.76431 6.90472 8.23851 6.90472C10.344 6.90472 12.1171 7.83281 13.3361 9.27342V7.19562H17.3808V23.6518H13.3361Z"
                fill="#B2FCE4"
            />
            <path
                d="M37.0507 19.9948C35.6378 19.9948 35.2361 19.4685 35.2361 18.0833V10.7833H37.8403V7.19558H35.2361V3.1785H31.0943V7.19558H25.7475V5.56104C25.7475 4.17584 26.2738 3.64946 27.7283 3.64946H28.6425V0.463501H26.6478C23.2264 0.463501 21.6057 1.58551 21.6057 5.00696V7.20943H19.3063V10.7833H21.6057V23.6518H25.7475V10.7833H31.0943V18.8451C31.0943 22.1973 32.3826 23.6518 35.7348 23.6518H37.868V19.9948H37.0507Z"
                fill="#B2FCE4"
            />
            <path
                d="M51.9278 13.9415C51.6369 11.8083 49.8915 10.5201 47.8414 10.5201C45.8052 10.5201 44.1152 11.7668 43.6997 13.9415H51.9278ZM43.6581 16.5042C43.949 18.9421 45.6944 20.3273 47.9107 20.3273C49.6561 20.3273 50.9997 19.5101 51.7893 18.1941H56.0418C55.0583 21.6848 51.9278 23.915 47.8137 23.915C42.8409 23.915 39.3502 20.4243 39.3502 15.4514C39.3502 10.4785 43.0348 6.89087 47.9107 6.89087C52.8143 6.89087 56.3743 10.5062 56.3743 15.4514C56.3743 15.8116 56.3466 16.1717 56.2773 16.5042H43.6581Z"
                fill="#B2FCE4"
            />
            <path
                d="M82.7485 15.4237C82.7485 12.7641 80.74 10.714 78.2743 10.714C75.8086 10.714 73.8001 12.6949 73.8001 15.4237C73.8001 18.1249 75.8086 20.1334 78.2743 20.1334C80.74 20.1334 82.7485 18.0972 82.7485 15.4237ZM69.7137 30.37V7.19562H73.7585V9.32882C74.9775 7.81896 76.7921 6.89087 78.953 6.89087C83.3995 6.89087 86.8902 10.5478 86.8902 15.3822C86.8902 20.2165 83.3303 23.9011 78.8561 23.9011C76.7783 23.9011 75.0745 23.0839 73.8832 21.6987V30.3562H69.7137V30.37Z"
                fill="#B2FCE4"
            />
            <path
                d="M101.476 15.4237C101.476 12.6533 99.4679 10.714 97.0022 10.714C94.5366 10.714 92.528 12.6949 92.528 15.4237C92.528 18.1249 94.5366 20.1334 97.0022 20.1334C99.4679 20.1334 101.476 18.1941 101.476 15.4237ZM101.518 23.6518V21.5186C100.299 23.0008 98.4844 23.915 96.3235 23.915C91.8077 23.915 88.3863 20.2996 88.3863 15.4237C88.3863 10.5894 91.9463 6.90472 96.4204 6.90472C98.526 6.90472 100.299 7.83281 101.518 9.27342V7.19562H105.563V23.6518H101.518Z"
                fill="#B2FCE4"
            />
            <path
                d="M62.4137 8.80245C62.4137 8.80245 63.4388 6.89087 65.9737 6.89087C67.0542 6.89087 67.7468 7.26488 67.7468 7.26488V11.462C67.7468 11.462 66.223 10.5201 64.824 10.714C63.4249 10.908 62.5384 12.1823 62.5384 13.9V23.638H58.3551V7.19562H62.3999V8.80245H62.4137Z"
                fill="#B2FCE4"
            />
        </svg>
    );
};

export default Afterpay;
