import * as React from "react";

const SvgFlowerCheck = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={3.134} y={2.774} width={12} height={12} rx={1} fill="#0E1311" />
    <rect
      width={12}
      height={12}
      rx={1}
      transform="rotate(-45 10.916 3.604)"
      fill="#0E1311"
    />
    <path
      d="M11.942 5.93a.778.778 0 0 1 .523-.193c.195.001.381.073.52.2.139.128.22.301.224.483a.677.677 0 0 1-.197.494l-3.99 4.674a.791.791 0 0 1-1.08.019L5.296 9.127a.705.705 0 0 1-.173-.228.665.665 0 0 1 .154-.783.756.756 0 0 1 .25-.154.795.795 0 0 1 .586.01.751.751 0 0 1 .243.162l2.094 1.96 3.473-4.144a.28.28 0 0 1 .02-.02Z"
      fill="#fff"
    />
  </svg>
);

export default SvgFlowerCheck;
