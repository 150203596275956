import Image from './Image'
import Link from 'next/link'
import GbLogo from '../../../public/logo.png'

export default function Logo(props) {
	return (
		<Link href='/'>
			<Image blur={false} src={GbLogo} priority={true} width={218} height={80} alt='Gourmet Basket' {...props} />
		</Link>
	)
}
